// 2/18
//used this.state with a toggleModal(), tried Comfirmation.toggle(), created Confirmation component with bootstrap
//maybe create a separate function that utilizes confirmation.toggle() or confirmation.hide()
//try having the class change display none. --> Sarah check Relay app

import React, { Component } from "react";
import Confirmation from "./Confirmation";
import EmailStatus from "./EmailStatus";
import API from "../API";

class Form extends Component {
  state = {
    selection: "",
    subaccountOptions: [],
    templateOptions: [],
    templateName: "",
    subaccountSelection: "",
    templateSelection: [],
    templateHTML: "",
    subject: "",
    fromAddress: "",
    fromName: "",
    campaign: "",
    templateID: "",
    testEmails: [],
    listUpload: [],
    sendStatus: "",
    modalStatus: false,
  };

  componentDidMount = async () => {
    await API.getSubaccounts()
      .then((response) => {
        this.setState({ ...this.state, subaccountOptions: response });
      })
      .catch((err) => console.log(err));
  };

  templateUpload = () => {
    if (this.state.templateID === "") {
    } else {
      API.createTemplate(this.state);
    }
  };

  handleInputChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    await this.setState({
      [name]: value,
    });
  };

  showFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      this.setState({ ...this.state, templateHTML: text });
    };
    reader.readAsText(e.target.files[0]);
  };

  getTextFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const emailText = e.target.result;
      let emailList = emailText.split("\n");
      let emailArray = emailList.map((singleEmail) => {
        return { address: { email: singleEmail } };
      });
      this.setState({ ...this.state, listUpload: emailArray });
    };
    reader.readAsText(e.target.files[0]);
  };

  onSubaccountChange = async (e) => {
    API.getTemplate(e.target.value).then((response) => {
      this.setState({ ...this.state, templateOptions: response });
    });
  };

  onSubmit = async () => {
    if (this.state.testEmails != "") {
      let testList = this.state.testEmails.split(", ");
      let testArray = testList.map((singleEmail) => {
        return { address: { email: singleEmail } };
      });
      await this.setState({ ...this.state, testEmails: testArray });
    }
    // await this.templateUpload();
    // await API.send(this.state)
    // .then(res => this.setState({...this.state, sendStatus: res.status, modalStatus:true}))
    // console.log(this.state.sendStatus)
    // get status of sent emails
    // trigger second modal based on status
  };

  onSubmitTest = async () => {
    if (this.state.testEmails != "") {
      console.log(this.state.testEmails);
      let testList = this.state.testEmails.split(", ");
      let testArray = testList.map((singleEmail) => {
        return { address: { email: singleEmail } };
      });
      await this.setState({ ...this.state, testEmails: testArray });
    }
    // await this.templateUpload();
    // await API.send(this.state);
    //   .then(res => this.setState({...this.state, sendStatus: res.status, modalStatus:true}))
    // console.log(this.state.sendStatus)
    // get status of sent emails
    // trigger second modal based on status
  };

  render() {
    return (
      <div>
        <form>
          <div className="form-group subaccount">
            <label className="form-label"> Subaccount </label>
            <div className="description">
              Subaccount populated by Sparkpost{" "}
            </div>
            <select
              id="subaccountSelection"
              name="subaccountSelection"
              className="form-control"
              onChange={(e) => {
                this.handleInputChange(e);
                this.onSubaccountChange(e);
              }}
            >
              <option value="Select One"> Select One</option>
              {this.state.subaccountOptions.map((item) => (
                <option key={item.name} value={item.name}>
                  {item.name}{" "}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group subaccount">
            <label className="form-label"> Template In Sparkpost Already</label>
            <div className="description">
              First choose subaccount, and then choose template options
              populated by Sparkpost{" "}
            </div>
            <select
              id="templateSelection"
              name="templateSelection"
              className="form-control"
              onChange={this.handleInputChange}
            >
              <option value="Select One"> Select One</option>
              {this.state.templateOptions.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}{" "}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group templateName">
            <label className="form-label">Template Name </label>
            <div className="description">
              If you are uploading your own HTML and not pulling from Sparkpost,
              name your template here.
            </div>
            <input
              type="text"
              className="form-control"
              name="templateName"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group HTMLUpload">
            <label className="form-label"> HTML Upload </label>
            <div className="description">
              Upload your completed HTML if you are not choosing a template that
              is already in Sparkpost.
            </div>
            <input
              type="file"
              action="upload"
              name="templateHTML"
              accept="html"
              onChange={(e) => this.showFile(e)}
            />
          </div>
          <div className="form-group template-id">
            <label className="form-label">Template ID </label>
            <div className="description">
              If you are uploading your HTML, add a Template ID, which is your
              Template Name, all lowercase with hyphens between each word.
              example: template-name
            </div>
            <input
              type="text"
              className="form-control"
              name="templateID"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group subject-line">
            <label className="form-label">Subject Line </label>
            <div className="description">
              Email's Subject Line, as provided by Client Services
            </div>
            <input
              type="text"
              className="form-control"
              name="subject"
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group from-address">
            <label className="form-label">From Address </label>
            <div className="description">
              The email address recipients will see when email is sent
            </div>
            <input
              type="text"
              className="form-control"
              name="fromAddress"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group from-name">
            <label className="form-label">From Name </label>
            <div className="description">
              The name recipients will see when email is sent
            </div>
            <input
              type="text"
              className="form-control"
              name="fromName"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group campaign">
            <label className="form-label">Campaign </label>
            <div className="description">Add your campaign</div>
            <input
              type="text"
              className="form-control"
              name="campaign"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group list-upload">
            <label className="form-label"> Recipient List Upload </label>
            <div className="description">
              Add a text file of email addresses if your recipient list is not
              in Sparkpost already.
            </div>
            <input
              type="file"
              action="upload"
              accept=".txt"
              onChange={(e) => this.getTextFile(e)}
            />
          </div>
          <div className="form-group test-emails">
            <label className="form-label">Test Emails </label>
            <div className="description">
              Add a list of emails by hand if you are not uploading a list of
              using a list already inmail addresses should be separated by a
              comma and a space
            </div>
            <input
              type="text"
              id="emails"
              className="form-control"
              name="testEmails"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="both-buttons">
            <button
              type="button"
              className="btn btn-secondary btn-lg"
              id="test-send-button"
              onClick={() => {
                this.onSubmitTest();
                this.templateUpload();
              }}
            >
              Test
            </button>
            <Confirmation
              onSelect={this.onSelect}
              onSubmit={() => {
                this.onSubmit();
              }}
            />
            <EmailStatus
              selection={"yes"}
              status={this.state.sendStatus}
              modalStatus={this.state.modalStatus}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default Form;

import './App.css';
import Form from './components/Form';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <header className="header">
       One Off Sender
      </header>
      <Form />
    </div>
  );
}

export default App;

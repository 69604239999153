import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Confirmation(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button id="live-send-button" variant="primary" onClick={handleShow}>
        Live Send
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to send?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            THIS IS FOR TESTING PURPOSES ONLY- THIS CURRENTLY WILL NOT SEND.
            THIS IS FOR SECURITY REASONS
          </p>
          Make sure you have the following: Subject Line, From Address,
          Campaign, Template ID, Subaccount, List ID, File uploaded, and emails.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Not Yet
          </Button>
          <Button
            variant="success"
            onClick={() => {
              props.onSubmit();
              handleClose();
            }}
          >
            Yes, Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Confirmation;

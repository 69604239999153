import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
function EmailStatus({ selection, status, modalStatus }) {

  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);


  if (selection == "yes" && status == 200 && modalStatus) {
    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Email Status: </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your emails have been sent successfully!
          </Modal.Body>
        </Modal>
      </>
    )
  }
  else if (selection == "yes" && status !==200 && modalStatus) {
    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Email Status: </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            There was an error!
            </Modal.Body>
        </Modal>
      </>
    )
  }

  else {
    return (
      <>
      </>
    )
  }
}

export default EmailStatus;
const fetch = require("node-fetch");
const API = {
    async getSubaccounts() {
        let response = await fetch("/api/subaccounts");
        let body = await response.json();
        return body.results;
    },

    async getTemplate(account) {
        let response = await fetch("/api/templates/" + account);
        let templates = await response.json();
        return templates.results;
    },

    async createTemplate(template) {
        let templateData = {
            id: template.templateID,
            name: template.templateName,
            content: {
                subject: template.subject,
                html: template.templateHTML,
                from: {
                    email: template.fromAddress,
                    name: template.fromName,
                },
            },
        }; // if templateData is good, good
        //if templateData is incomplete, don't send to sparkpost
        let response = await fetch("/api/upload-template", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(templateData),
        });
    },

    async send(email) {
        let recipients;
        if (email.testEmails){
            recipients = email.testEmails
        }
        else{
            recipients = email.listUpload
        }
        let emailObject = {
            content: {
                template_id: email.templateID
                    ? email.templateID
                    : email.templateSelection,
                use_draft_template: true,
            },
            recipients: recipients
        };
        let response = await fetch("/api/send", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(emailObject),
        });
        return (await response.json())
    }
};

export default API;
